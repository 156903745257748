import {
    mdiCheck,
    mdiCheckDecagram,
    mdiCreation,
    mdiDraw,
    mdiEyeOutline,
    mdiHelp,
    mdiLock,
    mdiLockOpen,
    mdiUndoVariant
} from "@mdi/js";

export const dictMonths = [
    {
        text: 'Январь',
        value: 1
    },
    {
        text: 'Февраль',
        value: 2
    },
    {
        text: 'Март',
        value: 3
    },
    {
        text: 'Апрель',
        value: 4
    },
    {
        text: 'Май',
        value: 5
    },
    {
        text: 'Июнь',
        value: 6
    },
    {
        text: 'Июль',
        value: 7
    },
    {
        text: 'Август',
        value: 8
    },
    {
        text: 'Сентябрь',
        value: 9
    },
    {
        text: 'Октябрь',
        value: 10
    },
    {
        text: 'Ноябрь',
        value: 11
    },
    {
        text: 'Декабрь',
        value: 12
    }
]
export const statusKeys = {
    new: "New",
    in_progress: "In progress",
    ready: "Ready",
    declined: "Declined",
    accepted: "Accepted",
    open: "Opened",
    closed: "Closed",
    signed: "Signed",
}
export function getStatusIcon(status) {
    switch (status) {
        case statusKeys.new:
            return mdiCreation;
        case statusKeys.in_progress:
            return mdiEyeOutline;
        case statusKeys.ready:
            return mdiCheck;
        case statusKeys.declined:
            return mdiUndoVariant;
        case statusKeys.accepted:
            return mdiCheckDecagram;
        case statusKeys.open:
            return mdiLockOpen;
        case statusKeys.closed:
            return mdiLock;
        case statusKeys.signed:
            return mdiDraw;
        default:
            return mdiHelp;
    }
}
export function getStatusColor(status) {
    switch (status) {
        case statusKeys.new:
            return "";
        case statusKeys.in_progress:
            return "secondary"
        case statusKeys.ready:
            return "info";
        case statusKeys.declined:
            return "warning"
        case statusKeys.accepted:
            return "success";
        case statusKeys.open:
            return "";
        case statusKeys.closed:
            return "info";
        case statusKeys.signed:
            return "primary";
        default:
            return "";
    }
}
export function getStatusText(status) {
    switch (status) {
        case statusKeys.new:
            return "Создано";
        case statusKeys.in_progress:
            return "В работе"
        case statusKeys.ready:
            return "Сохранено";
        case statusKeys.declined:
            return "Отклонено"
        case statusKeys.accepted:
            return "Принято";
        case statusKeys.open:
            return "Открыто";
        case statusKeys.closed:
            return "Закрыто";
        case statusKeys.signed:
            return "Подписано";
        default:
            return status;
    }
}

export function getSetStatusBtnText(status) {
    switch (status) {
        case statusKeys.new:
            return "Начать";
        case statusKeys.in_progress:
            return "Взять в работу"
        case statusKeys.ready:
            return "Сохранить";
        case statusKeys.declined:
            return "Отклонить"
        case statusKeys.accepted:
            return "Принять";
        default:
            return status;
    }
}

export function getMonthName(month) {
    const m = parseInt(month)
    switch (m) {
        case 1:
            return "Январь"
        case 2:
            return "Февраль"
        case 3:
            return "Март"
        case 4:
            return "Апрель"
        case 5:
            return "Май"
        case 6:
            return "Июнь"
        case 7:
            return "Июль"
        case 8:
            return "Август"
        case 9:
            return "Сентябрь"
        case 10:
            return "Октябрь"
        case 11:
            return "Ноябрь"
        case 12:
            return "Декабрь"
        default:
            return "Неизвестный месяц"
    }
}