<script>
import {
  downloadDepartmentFile,
  getDepartments, getManagersOfDepartment,
} from "@/services/api/requests";
import {mapGetters} from "vuex";
import {mdiArrowRight} from "@mdi/js";
import Status from "@/components/Status.vue";
import CardUser from "@/components/CardUser.vue";
import {getMonthName, statusKeys} from "@/services/mappers";

export default {
  name: "Departments",
  components: {CardUser, Status},
  data: function () {
    return {
      mdiArrowRight,
      year: null,
      month: null,
      loadingDepartments: false,
      loadingDepartment: false,
      downloadingDepartmentFile: false,
      departments: [],
      expanded: [],
    }
  },
  computed: {
    statusKeys() {
      return statusKeys
    },
    ...mapGetters("User", {
        isAdmin1: "isAdmin1",
    }),
      isUserAllowed() {
        return this.isAdmin1
      },
  },
  async created() {
    this.year = this.$route.query.year
    this.month = this.$route.query.month
    if (!this.year || !this.month || !this.isUserAllowed) {
      await this.$router.push('/')
    }
    this.loadingDepartments = true
    await this.fetchDepartments()
    this.loadingDepartments = false
  },
  methods: {
    getMonthName,
    onChange(selectedDepartmentId) {
      const selectedDepartment = this.departments[selectedDepartmentId]?.id
      if (selectedDepartment) {
        this.fetchManagersOfDepartment(selectedDepartment)
      } else {
        this.expanded = []
      }
    },
    async fetchDepartments() {
      try {
        this.departments = await getDepartments(this.year, this.month)
      } catch (e) {
        console.log(e)
      }
    },
    async fetchManagersOfDepartment(selectedDepartment) {
      this.expanded = []
      this.loadingDepartment = true
      this.expanded = await getManagersOfDepartment(this.year, this.month, selectedDepartment)
      setTimeout(() => {
        this.loadingDepartment = false
      }, 600)
    },
    getLocalDate(date) {
      return new Date(date).toLocaleDateString()
    },
    goToFormular(formularId) {
      this.$router.push({name:'Formular', params: {formularId}})
    },
    async downloadDepartmentFile(year, month, departmentId) {
      try {
        this.downloadingDepartmentFile = true
        const link = await downloadDepartmentFile(year, month, departmentId)
        link.download = `Премии_формуляр_${this.formularMonth}_${this.formularYear}.pdf`;
        document.body.appendChild(link);
        link.click();
        setTimeout(()=> this.downloadingDepartmentFile = false, 100)
      } catch (e) {
        this.downloadingDepartmentFile = false
      }
    }
  }
}
</script>

<template>
  <v-container>
    <h1 class="text-h5 text-center mt-10 mb-5">
      {{ getMonthName(month) }} {{ year }}
    </h1>
    <div
      v-if="loadingDepartments"
      class="text-center d-flex flex-column justify-center align-center my-10"
      style="width: 100%"
    >
      <v-progress-circular
        indeterminate
        height="60"
        color="primary"
      />
      <span class="mt-5">Загружаются статусы по обособленным подразделениям</span>
    </div>
    <template v-if="departments.length > 0">
      <v-expansion-panels
        popout
        flat
        @change="onChange($event)"
      >
        <v-expansion-panel
          v-for="{department_name: name, status, id: departmentId} in departments"
          :key="departmentId"
        >
          <v-expansion-panel-header class="flex justify-space-between pa-2">
            <span>{{ name }}</span>
            <Status
              :status="status"
              class="flex-grow-0 flex-shrink-0"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-if="loadingDepartment"
              class="text-center d-flex flex-column justify-center align-center my-10"
              style="width: 100%"
            >
              <v-progress-circular
                indeterminate
                height="60"
                color="primary"
              />
              <span class="mt-5">Загружаются статусы по менеджерам обособленного подразделения
                <strong>{{ name }}</strong>
              </span>
            </div>
            <template v-else-if="expanded.length > 0">
              <div v-if="status === statusKeys.signed">
                <span class="mr-2">
                  Cкачать подписанный формуляр по обособленному подразделению:
                </span>
                <v-btn
                  color="primary"
                  depressed
                  small
                  :loading="downloadingDepartmentFile"
                  @click="downloadDepartmentFile(year, month, departmentId)"
                >
                  Скачать
                </v-btn>
              </div>
              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Менеджер
                      </th>
                      <th class="text-left">
                        Статус
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="{manager, id, status: statusManager} in expanded"
                      :key="manager"
                    >
                      <td>
                        <CardUser :user="manager" />
                      </td>
                      <td>
                        <Status
                          small
                          :status="statusManager"
                        />
                      </td>
                      <td>
                        <v-btn
                          color="primary"
                          text
                          small
                          @click="goToFormular(id)"
                        >
                          <span>к формуляру</span>
                          <v-icon
                            small
                            class="mt-n1 ml-2"
                          >
                            {{ mdiArrowRight }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-container>
</template>
